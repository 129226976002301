import config from '../config';

const cognitoProvider = 'CognitoIdentityServiceProvider';

export const getCookieUserName = () => {
  const cookieName = `${cognitoProvider}.${config.cognito.userPoolClientId}.LastAuthUser`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
  const cookieResult = cookieValue ? cookieValue.pop() : null;
  return `${cognitoProvider}.${config.cognito.userPoolClientId}.${cookieResult}`;
};

export const getCookieAccessToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.accessToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  return cookieResult;
};

export const getCookieIdToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.idToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  return cookieResult;
};

export const getCookieRefreshToken = () => {
  const cookiePrefix = getCookieUserName();
  const cookieName = `${cookiePrefix}.refreshToken`;
  const cookieValue = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');

  const cookieResult = cookieValue ? cookieValue.pop() : null;
  return cookieResult;
};
