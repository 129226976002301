import React from 'react';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import { FooterBottomOuter, FooterBottomInner, FooterLogoWrapper, FooterLinksWrapper, FooterLinks } from './styles';
// import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
// import { BsTwitterX } from 'react-icons/bs';

const Footer = () => {
  return (
    <FooterBottomOuter>
      <FooterBottomInner>
        <FooterLogoWrapper>
          <FooterLinks href={isAuthenticated() ? '/dashboard' : '/'} style={{ margin: 'unset' }}>
            © Leyaa
          </FooterLinks>
        </FooterLogoWrapper>

        <FooterLinksWrapper>
          <FooterLinks href="/contact">Contact Us</FooterLinks>
          <FooterLinks href="/blog">Blog</FooterLinks>
          <FooterLinks href="/terms">Terms of Service</FooterLinks>
          <FooterLinks href="/privacy-policy">Privacy Policy</FooterLinks>
          <FooterLinks href="/fair-usage-policy">Fair Usage Policy</FooterLinks>
          <FooterLinks href="/refund-policy">Refund Policy</FooterLinks>
        </FooterLinksWrapper>
      </FooterBottomInner>
    </FooterBottomOuter>
  );
};

export default Footer;
