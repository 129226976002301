import {
  INIT_SIGNIN,
  INIT_SIGNOUT,
  TOGGLE_SITE_THEME,
  UPDATE_USER_LOCATION_DETAILS,
  UPDATE_TRIAL_USAGE_COUNT,
  UPDATE_TRIAL_STATUS,
  // UPDATE_TOTAL_USAGE_COUNT,
  UPDATE_USAGE_THRESHOLD,
} from './actionTypes';

export const InitSignin = (user) => ({
  type: INIT_SIGNIN,
  user,
});

export const InitSignOut = () => ({
  type: INIT_SIGNOUT,
});

export const toggleSiteTheme = (themeStatus) => ({
  type: TOGGLE_SITE_THEME,
  themeStatus,
});

export const updateUserLocationDetails = (locationDetails) => ({
  type: UPDATE_USER_LOCATION_DETAILS,
  locationDetails,
});

export const updateTrialUsageCount = (usageCount) => ({
  type: UPDATE_TRIAL_USAGE_COUNT,
  usageCount,
});

export const updateTrialStatus = (status) => ({
  type: UPDATE_TRIAL_STATUS,
  status,
});

// export const updateTotalUsageCount = (usageCount) => ({
//   type: UPDATE_TOTAL_USAGE_COUNT,
//   usageCount,
// });

export const updateUsageThreshold = (status) => ({
  type: UPDATE_USAGE_THRESHOLD,
  status,
});
