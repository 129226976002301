import React, { useEffect } from 'react';
import Lottie from 'lottie-web-light';
import config from '../../config';
import lottieJsonOptions from '../../Data/lottieJsonOptions';
// import reactLogo from '../../../Animations/home-page-old-path.json';
import HomePageSvg from '../../Animations/home-page-path-11-dec-2024-12-33.json';
import testverseBannerSvg from '../../Animations/testverse-11-dec-12-12-2024.json';
import {
  HomeBannerOuter,
  HomeBannerInner,
  HomePrimaryContent,
  HomeLogo,
  HomeHeading,
  BannerDescriptionText,
  ButtonContainer,
  HomeSecondaryContent,
  PackagesVideoWrapper,
} from '../UI/Banner/styles';
import SiteButton from '../UI/FormComponents/SiteButton';

export default function Banner() {
  useEffect(() => {
    let bannerSvgDiv = document.querySelector('#home-banner-svg');
    if (bannerSvgDiv && bannerSvgDiv.innerHTML === '') {
      Lottie.loadAnimation({
        container: bannerSvgDiv,
        // animationData: HomePageSvg,
        animationData: testverseBannerSvg,
        ...lottieJsonOptions,
      });
    }
    // console.log('inside UseEffect:', bannerSvgDiv.innerHTML);

    // const EmptySvgDiv = () => {
    //   if (bannerSvgDiv) {
    //     bannerSvgDiv.innerHTML = '';
    //   }
    // };

    // window.addEventListener('beforeunload', EmptySvgDiv);
    // return () => {
    //   window.removeEventListener('beforeunload', EmptySvgDiv);
    // };
  }, []);

  return (
    <HomeBannerOuter>
      <HomeBannerInner>
        <HomePrimaryContent>
          {/*<HomeLogo src={config.cloudFront + "/Assets/Icons/leyaa-lts-medium.svg"} alt="" />*/}
          <HomeHeading>
            {/*<span className="d-block d-sm-inline-block">The intuitive AI platform</span> to supercharge your <span className="d-block d-sm-inline-block">learning outcomes.</span>*/}
            {/*The adaptive AI platform to supercharge your learning outcomes*/}
            <span className="d-block">Your learning. Your way.</span>
            Master any skill with <span className="blueGradientTxt">Adaptive AI</span>
          </HomeHeading>

          <BannerDescriptionText>
            {/*Maximize productivity with our intuitive AI platform. Save time and enhance your efficiency by simplifying tasks with intelligent AI solutions.*/}
            {/*Elevate your learning with Leyaa. Assess your knowledge, get tailored learning paths, instant doubt resolution, and practice with custom tests and mock interviews.*/}
            Leyaa helps you evaluate your skills, unlock personalized learning paths, clear doubts instantly, and sharpen skills with tailored tests and mock interviews.
          </BannerDescriptionText>

          <ButtonContainer>
            <SiteButton bannerBtn onClick={() => (window.location.href = '/signup')}>
              Get started for free
            </SiteButton>
          </ButtonContainer>
        </HomePrimaryContent>

        <HomeSecondaryContent>
          <PackagesVideoWrapper id="home-banner-svg" />
        </HomeSecondaryContent>
      </HomeBannerInner>
    </HomeBannerOuter>
  );
}
