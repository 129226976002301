import { applyMiddleware, compose, createStore } from 'redux';
import AppReducer from '../Reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { createBrowserHistory } from 'history';
import { thunk } from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
};

export const history = createBrowserHistory();

const configureStore = (preloadedState) => {
  const persistedReducer = persistReducer(persistConfig, AppReducer(history));
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(persistedReducer, preloadedState, composeEnhancer(applyMiddleware(thunk, routerMiddleware(history))));

  return {
    store,
    persistor: persistStore(store),
  };
};

export default configureStore;
