import React, { Fragment, useState } from 'react';
import { Accordion } from 'react-accessible-accordion';
import { isAuthenticated } from '../../../Routes/ProtectedRoute';
import config from '../../../config';
import productsData from '../../../Data/productsData';
import { HeaderSecondaryLink } from '../Header/styles';
import { HomeSideMenuContainer, SideMenuOverlay, HomeSideMenuWrapper, SideNavInnerContainer, SideNavLink, SideNavDiv } from './styles/HomeSideNav';
import { DropdownNavIcon, DropdownNavText, DropdownNavDescText } from '../Header/HeaderDropdown/styles';
import SideNavAccordian from '../Header/styles/SideNavAccordians';
import SiteButton from '../FormComponents/SiteButton';
import { MdOutlineArrowOutward } from 'react-icons/md';

export default function HomeSideNav({ show, closeSideMenu, headerPricingLink }) {
  const [activeDropdown, setActiveDropdown] = useState(true);
  const dropdownToggle = (dropdownId) => {
    if (activeDropdown === -1) {
      setActiveDropdown(dropdownId);
    } else if (activeDropdown === dropdownId) {
      setActiveDropdown(-1);
    } else {
      setActiveDropdown(dropdownId);
    }
  };
  return (
    <HomeSideMenuContainer show={show}>
      <SideMenuOverlay show={show} onClick={closeSideMenu} />
      <HomeSideMenuWrapper show={show}>
        <Accordion preExpanded={[0]} allowZeroExpanded={true} allowMultipleExpanded={false}>
          <SideNavAccordian groudId={0} groupName="Products" activeGroupId={activeDropdown} onClick={() => dropdownToggle(0)} onKeyDown={() => dropdownToggle(0)}>
            <div className="py-2">
              {productsData.map(({ productID, productIcon, productDisplayName, productPageLink, productDesc }) => (
                <SideNavDiv key={productID} href={productPageLink}>
                  <DropdownNavIcon src={config.cloudFront + productIcon} alt="" />
                  <div className="no-line-height">
                    <DropdownNavText>{productDisplayName}</DropdownNavText>
                    <DropdownNavDescText>{productDesc}</DropdownNavDescText>
                  </div>
                </SideNavDiv>
              ))}
            </div>
          </SideNavAccordian>
        </Accordion>

        <SideNavLink href={headerPricingLink}>
          <p className="me-2">Pricing</p> <MdOutlineArrowOutward className="fs-18" />
        </SideNavLink>
        <hr className="my-0" />

        {!isAuthenticated() ? (
          <div className="hideOnLargeMobile mt-5">
            <div className="d-flex align-items-center justify-content-center px-2">
              <HeaderSecondaryLink href="/signin">Sign in</HeaderSecondaryLink>
              <SiteButton headerBtn style={{ marginLeft: '2rem' }} onClick={() => (window.location.href = '/signup')}>
                Try for free
              </SiteButton>
            </div>
          </div>
        ) : null}
      </HomeSideMenuWrapper>
    </HomeSideMenuContainer>
  );
}
