import styled from 'styled-components';

export const SiteWrapper = styled.main.attrs({ id: 'site-wrapper' })`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--txtDescriptionColor);
  background: #fff;
`;

export const SectionOuter = styled.section`
  display: flex;
  flex-grow: 1;
  padding: 40px 0px;
  background-color: ${({ secondarySection }) => secondarySection && 'var(--bgPrimaryColor)'};
`;

export const SectionInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  @media (min-width: 992px) {
    // width: 95%;
  }
`;

export const SectionHeading = styled.h2`
  font-size: 24px;
  font-family: var(--headingFontSemiBold);
  text-align: center;
  padding-bottom: 24px;
  @media (min-width: 481px) {
    font-size: 28px;
  }
  @media (min-width: 576px) {
    font-size: 32px;
  }
  @media (min-width: 992px) {
    font-size: 34px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
  }
  @media (min-width: 1400px) {
    font-size: 40px;
  }
  @media (min-width: 1800px) {
    font-size: 42px;
  }
`;

// Feature section styles
export const FeaturesOuterContainer = styled.div`
  text-align: center;
  padding: 30px 0px;
  background-color: var(--bgDboardLightColor);
`;

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0px;
  }
  &:nth-child(even) {
    @media (min-width: 992px) {
      flex-direction: row-reverse;
    }
  }
`;

export const FeatureContent = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 481px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    width: 44%;
    padding-bottom: unset;
  }
`;

export const FeatureTitle = styled.h2`
  text-align: left;
  padding-bottom: 24px;
  color: var(--txtHeadingColor);
  font-size: 24px;
  font-family: var(--headingFontSemiBold);
  padding-bottom: 16px;
  @media (min-width: 481px) {
    font-size: 26px;
  }
  @media (min-width: 576px) {
    font-size: 28px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
  }
  @media (min-width: 1200px) {
    font-size: 34px;
  }
  @media (min-width: 1400px) {
    font-size: 36px;
  }
  @media (min-width: 1800px) {
    font-size: 40px;
  }
`;

export const FeatureDescription = styled.p`
  text-align: left;
  font-size: 15px;
  @media (min-width: 992px) {
    font-size: 16px;
  }
  @media (min-width: 1400px) {
    font-size: 18px;
  }
`;

export const FeatureAnimationWrapper = styled.div`
  width: 100%;
  min-height: 120px;
  margin: 0px auto;
  @media (min-width: 481px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    width: 52%;
    margin: unset;
  }
`;

export const FeatureAnimation = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 1px 4px var(--greyShadowColor);
`;
