import React from 'react';
import styled, { keyframes } from 'styled-components';

// const GrowingLine = keyframes`
//   0% {
//     width: 0%;
//   }
//   6% {
//     width: 0%;
//   }
//   20% {
//     width: 20%;
//   }
//   36% {
//     width: 20%;
//   }
//   55% {
//     width: 55%;
//   }
//   66% {
//     width: 55%;
//   }
//   80% {
//     width: 80%;
//   }
//   86% {
//     width: 80%;
//   }
//   100% {
//     width: 100%;
//   }
// `;

const MovingBar = keyframes`
  0% {background-position:-150% 0,-150% 0}
  66% {background-position: 250% 0,-150% 0}
  100% {background-position: 250% 0, 250% 0}
`;

const LoaderDiv = styled.div`
  position: relative;
  height: 100vh;
  background: #fff;
`;

// const LoaderImg = styled.img`
//   position: absolute;
//   width: 180px;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   @media (min-width: 768px) {
//     width: 240px;
//   }
//   @media (min-width: 1200px) {
//     width: 280px;
//   }
//   @media (min-width: 2000px) {
//     width: 300px;
//   }
// `;

const LoaderText = styled.h2`
  position: absolute;
  display: iniline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SpinnerLoaderDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const SpinnerLoaderCircle = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid var(--bgBrandColor);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.p`
  display: inline-block;
  color: var(--txtHeadingColor);
  font-size: 20px;
  font-family: var(--commonFontMedium);
  margin-left: 16px;
`;

const LineLoaderWrapper = styled.div`
  --loadingLine: no-repeat linear-gradient(var(--bgBrandColor) 0 0);
  width: 100%;
  height: 4px;
  position: fixed;
  top: 0px;
  left: 0px;
  background: var(--loadingLine), var(--loadingLine), var(--skyBorderColor);
  background-size: 50% 100%;
  animation: ${MovingBar} 2.5s linear infinite;
`;

// const LoadingLine = styled.div`
//   position: relative;
//   height: 100%;
//   width: 40%;
//   background: linear-gradient(to right, var(--bgBrandColor), #7465ff, #836fff, #9079ff, var(--bgBrandColor));
//   animation: ${MovingBar} 2s ease-in infinite;
// `;

export const Loader = ({ wrapperStyle }) => (
  <LoaderDiv style={wrapperStyle}>
    <LoaderText>Loading...</LoaderText>
  </LoaderDiv>
);

export const SpinnerLoader = ({ wrapperStyle, spinnerStyle, loadingText }) => (
  <SpinnerLoaderDiv style={wrapperStyle}>
    <SpinnerLoaderCircle style={spinnerStyle} />
    <LoadingText>{loadingText ? loadingText : 'Loading...'}</LoadingText>
  </SpinnerLoaderDiv>
);

export const SigninLineLoader = ({ wrapperStyle }) => <LineLoaderWrapper style={wrapperStyle} />;
