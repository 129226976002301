/* eslint-disable */
import React from 'react';
import { ReactComponent as SiteFormSvg } from './leyaa-header-logo.svg';
import { ReactComponent as SiteHeaderSvg } from './leyaa-header-icon.svg';

export function SiteFormLogo() {
  return <SiteFormSvg />;
}

export function SiteHeaderIcon() {
  return <SiteHeaderSvg />;
}
