import {
  INIT_SIGNIN,
  INIT_SIGNOUT,
  TOGGLE_SITE_THEME,
  UPDATE_USER_LOCATION_DETAILS,
  UPDATE_TRIAL_USAGE_COUNT,
  UPDATE_TRIAL_STATUS,
  UPDATE_TOTAL_USAGE_COUNT,
} from '../Actions/actionTypes';

const INIT_STATE = {
  userID: null,
  username: 'User',
  email: null,
  picture: 'https://leyaa.ai/Assets/Icons/plan-tick.svg',
  phoneNumber: null,
  darkTheme: false,
  loginType: null,
  trialStatus: null,
  trialUsageCount: 10,
  trialTotalCount: 10,
  locationDetails: {},
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SIGNIN:
      return {
        ...state,
        ...action.user,
      };

    case INIT_SIGNOUT:
      return { ...INIT_STATE };

    case TOGGLE_SITE_THEME:
      return {
        ...state,
        darkTheme: action.themeStatus,
      };

    case UPDATE_USER_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: action.locationDetails,
      };

    case UPDATE_TRIAL_USAGE_COUNT:
      return {
        ...state,
        trialUsageCount: action.usageCount,
      };

    case UPDATE_TRIAL_STATUS:
      return {
        ...state,
        trialStatus: action.status,
      };

    // case UPDATE_TOTAL_USAGE_COUNT:
    //   return {
    //     ...state,
    //     totalUsageCount: action.usageCount,
    //   };

    default:
      return state;
  }
};

export default User;
