export const INIT_SIGNIN = 'INIT_SIGNIN';
export const INIT_SIGNOUT = 'INIT_SIGNOUT';
export const TOGGLE_SITE_THEME = 'TOGGLE_SITE_THEME';
export const UPDATE_USER_LOCATION_DETAILS = 'UPDATE_USER_LOCATION_DETAILS';
export const UPDATE_TRIAL_USAGE_COUNT = 'UPDATE_TRIAL_USAGE_COUNT';
export const UPDATE_TRIAL_STATUS = 'UPDATE_TRIAL_STATUS';

export const UPDATE_TOTAL_USAGE_COUNT = 'UPDATE_TOTAL_USAGE_COUNT';

export const UPDATE_USAGE_THRESHOLD = 'UPDATE_USAGE_THRESHOLD';
