import User from './user';
import Notification from './notification';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

const AppReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    User,
    Notification,
  });

export default AppReducer;
