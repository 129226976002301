import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Lottie from 'lottie-web-light';
import config from '../../config';
import axiosInstance from '../../Utils/interceptedApi';
import { isAuthenticated } from '../../Routes/ProtectedRoute';
import { getCountryCode } from '../../Utils/userLocation';
import { updateUserLocationDetails } from '../../Actions';
import lottieJsonOptions from '../../Data/lottieJsonOptions';
import firstFeatureSvg from '../../Animations/testverse-feature-one-28-nov-2024-06-45.json';
import secondFeatureSvg from '../../Animations/testverse-feature-one-28-nov-2024-06-45.json';
import thirdFeatureSvg from '../../Animations/testverse-feature-one-28-nov-2024-06-45.json';
import Header from '../UI/Header';
import Banner from './Banner';
import { FeaturesOuterContainer, FeatureWrapper, FeatureContent, FeatureTitle, FeatureDescription, FeatureAnimationWrapper, FeatureAnimation } from '../UI/Layout';
import { SectionInner, SectionHeading } from '../UI/Layout';
import Footer from '../UI/Footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { userLocationDetails, updateUserLocationDetails } = this.props;
    if (isAuthenticated()) {
      this.signedRedirection();
    } else {
      let countryCode = await getCountryCode(userLocationDetails, updateUserLocationDetails);
    }

    let firstFeatureSvgDiv = document.getElementById('home-first-feature');
    if (firstFeatureSvgDiv && firstFeatureSvgDiv.innerHTML === '') {
      Lottie.loadAnimation({
        container: firstFeatureSvgDiv,
        animationData: firstFeatureSvg,
        ...lottieJsonOptions,
      });
    }

    let secondFeatureSvgDiv = document.getElementById('home-second-feature');
    if (secondFeatureSvgDiv && secondFeatureSvgDiv.innerHTML === '') {
      Lottie.loadAnimation({
        container: secondFeatureSvgDiv,
        animationData: secondFeatureSvg,
        ...lottieJsonOptions,
      });
    }

    let thirdFeatureSvgDiv = document.getElementById('home-third-feature');
    if (thirdFeatureSvgDiv && thirdFeatureSvgDiv.innerHTML === '') {
      Lottie.loadAnimation({
        container: thirdFeatureSvgDiv,
        animationData: thirdFeatureSvg,
        ...lottieJsonOptions,
      });
    }
  }

  signedRedirection = () => (window.location.href = '/dashboard');

  updateLocation = async () => {
    const { userLocationDetails } = this.props;
    let apiPrefix = 'https://2j0t1q3uh1.execute-api.us-east-1.amazonaws.com';
    // let apiPrefix = 'http://localhost:3001';

    try {
      const apiResult = await axiosInstance(apiPrefix).post('/update-user-details', {
        ...userLocationDetails,
        type: 'locationDetails',
      });
      // console.log('location details data -->', apiResult.data);
      return apiResult.data;
    } catch (error) {
      console.log('usr_dtl: ', error.message);
      return null;
    }
  };

  render() {
    const { country } = this.props;

    return (
      <Fragment>
        <Header />
        <Banner />

        <FeaturesOuterContainer>
          <SectionInner>
            <SectionHeading>Features</SectionHeading>

            <FeatureWrapper>
              <FeatureContent>
                <FeatureTitle>Skill Evaluation & Personalized Learning Path</FeatureTitle>
                <FeatureDescription>
                  Leyaa.ai evaluates your skills and creates a customized learning journey just for you. Receive specific recommendations and resources that align with your unique
                  learning needs.
                </FeatureDescription>
              </FeatureContent>

              <FeatureAnimationWrapper>
                <FeatureAnimation id="home-first-feature" />
              </FeatureAnimationWrapper>
            </FeatureWrapper>

            <FeatureWrapper>
              <FeatureContent>
                <FeatureTitle>Instant Doubt Resolution with AI</FeatureTitle>
                <FeatureDescription>
                  With Leyaa.ai, your doubts are resolved instantly, whenever they arise. No more waiting or disrupting your flow—just uninterrupted learning, at your pace.
                </FeatureDescription>
              </FeatureContent>

              <FeatureAnimationWrapper>
                <FeatureAnimation id="home-second-feature" />
              </FeatureAnimationWrapper>
            </FeatureWrapper>

            <FeatureWrapper>
              <FeatureContent>
                <FeatureTitle>Prepare Smarter with Tests & Mock Interviews</FeatureTitle>
                <FeatureDescription>
                  Prepare smarter with Leyaa.ai's interactive tests and mock interviews. Gain valuable insights instantly, track your progress, and fine-tune your skills to perform
                  at your best.
                </FeatureDescription>
              </FeatureContent>

              <FeatureAnimationWrapper>
                <FeatureAnimation id="home-third-feature" />
              </FeatureAnimationWrapper>
            </FeatureWrapper>
          </SectionInner>
        </FeaturesOuterContainer>

        {/*<button className="p-2 btn btn-primary common-btn-radius" onClick={this.updateLocation} style={{ width: '240px' }}>
          Update location details
        </button>*/}
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  userLocationDetails: User.locationDetails,
  country: User.locationDetails && User.locationDetails.country,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserLocationDetails: (locationDetails) => dispatch(updateUserLocationDetails(locationDetails)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
