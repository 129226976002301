const prod = {
  domain: 'https://leyaa.ai',
  apiBasePath: 'https://api-dev.leyaa.ai',
  paymentService: 'https://api-dev.leyaa.ai/payments',
  dashboardService: 'https://api-dev.leyaa.ai/dashboard',
  aiService: 'https://api-dev.leyaa.ai/v1',
  cloudFront: 'https://leyaa.ai',
  cognito: {
    userPoolId: 'us-east-1_Iuah1zX6s',
    userPoolClientId: 'ejjcv48ddi95t4p3hljj6r7po',
    identityPoolId: 'us-east-1:17398512-17ea-40da-bd42-9bd351930616',
  },
};

const test = {
  domain: 'https://leyaa.ai',
  apiBasePath: 'https://api-dev.leyaa.ai',
  paymentService: 'https://api-dev.leyaa.ai/payments',
  dashboardService: 'http://localhost:3001',
  aiService: 'https://api-dev.leyaa.ai/v1',
  cloudFront: 'https://leyaa.ai',
  cognito: {
    userPoolId: 'us-east-1_Iuah1zX6s',
    userPoolClientId: 'ejjcv48ddi95t4p3hljj6r7po',
    identityPoolId: 'us-east-1:17398512-17ea-40da-bd42-9bd351930616',
  },
};

const config = prod;

export default config;
