const productsData = [
  {
    productID: 'testverse',
    pathID: 'testverse',
    productPrimaryName: 'Testverse',
    productDisplayName: 'Testverse',
    productIcon: '/Assets/productIcons/testverse.svg',
    productPageLink: '/testverse',
    pricingPageLink: '/testverse/pricing',
    productDesc: 'AI question generator',
  },
  // {
  //   productID: 'code-craft',
  //   pathID: 'code-craft',
  //   productPrimaryName: 'CodeCraft',
  //   productDisplayName: 'Codecraft',
  //   productIcon: '/Assets/productIcons/codecraft.svg',
  //   productPageLink: '/codecraft',
  //   pricingPageLink: '/codecraft/pricing',
  //   productDesc: 'AI coding companion',
  // },
  // {
  //   productID: 'super-learner',
  //   pathID: 'super-learner',
  //   productPrimaryName: 'SuperLearner',
  //   productDisplayName: 'Superlearner',
  //   productIcon: '/Assets/productIcons/superlearner.svg',
  //   productPageLink: '/superlearner',
  //   pricingPageLink: '/superlearner/pricing',
  //   productDesc: 'AI learning companion',
  // },
];

export default productsData;
