import Loadable from 'react-loadable';
import { SpinnerLoader } from '../Components/UI/Loader';

const LoadableHoc = (location) =>
  Loadable({
    loader: () => import('../Components/' + location),
    loading: SpinnerLoader,
  });

export default LoadableHoc;
