import React, { Fragment } from 'react';
import styled from 'styled-components';
import { HiOutlineArrowRight } from 'react-icons/hi';

export const RightArrow = styled(HiOutlineArrowRight)`
  position: relative;
  transition: var(--hoverTransitionDuration) transform ease-in-out;
  margin-left: 6px;
`;

export const SiteBtn = styled.button.attrs({ className: 'siteBtn' })`
  border: none;
  outline: none;
  padding: 8px;
  height: 45px;
  min-width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-family: var(--commonFontMedium);
  background: var(--bgBrandColor);
  border-radius: var(--buttonRadius);
  transition: var(--hoverTransitionDuration) ease-in-out;
  &:hover {
    filter: brightness(94%);
    outline: none;
  }
  &:hover ${RightArrow} {
    transform: translateX(4px);
  }
  &:active {
    filter: initial;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const BorderBtn = styled(SiteBtn).attrs({ className: 'borderBtn' })`
  color: var(--txtBrandColor);
  background: #fff;
  border: 1px solid var(--bgBrandColor);
  &:hover {
    filter: none;
    outline: none;
    background: var(--bgDboardLightColor);
  }
  &:active {
    filter: none;
    outline: none;
    background: var(--bgActiveLightColor);
  }
`;

export const ModalSecondaryBtn = styled(SiteBtn).attrs({ className: 'modalSecondaryBtn' })`
  color: var(--txtBrandColor);
  background: #fff;
  border: 1px solid var(--bgBrandColor);
  &:hover {
    filter: none;
    outline: none;
    background: var(--bgDboardLightColor);
  }
  &:active {
    filter: none;
    outline: none;
    background: var(--bgActiveLightColor);
  }
`;

export const BannerBtn = styled(SiteBtn).attrs({ className: 'bannerBtn' })`
  width: 100%;
  max-width: 320px;
  height: 54px;
  padding: 8px;
  font-size: 16px;
`;

export const HeaderBtn = styled(SiteBtn).attrs({ className: 'headerBtn' })`
  height: 38px;
  min-width: 130px;
`;

export const FormBtn = styled(SiteBtn).attrs({ className: 'formBtn' })`
  width: 100%;
`;

export const PricingBtn = styled(FormBtn).attrs({ className: 'pricingBtn' })`
  height: 45px;
  color: ${({ variant }) => (variant === 'white' || variant === 'border') && 'var(--txtBrandColor)'};
  background: ${({ variant }) => (variant === 'white' ? '#fff' : variant === 'border' ? 'transparent' : 'var(--bgBrandColor)')};
  border: ${({ variant }) => variant === 'border' && '1px solid var(--borderColor)'};
  &:hover {
    border: ${({ variant }) => variant === 'border' && '1px solid var(--bgBrandColor)'};
  }
`;

export const SpinnerBtn = styled(SiteBtn).attrs({ className: 'spinnerBtn' })`
  width: 100%;
  height: 50px;
  padding: 4px 8px;
`;

export const DboardBtn = styled(SpinnerBtn).attrs({ className: 'dboardBtn' })`
  height: 45px;
  max-width: 200px;
`;

const BtnLoadingCircle = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--bs-light);
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export default function SiteButton({
  onClick,
  style,
  children,
  active,
  disabled,
  noRightIcon,
  loadingText,
  id,
  ref,
  href,
  variant,
  commonBtn,
  commonBorderBtn,
  modalSecondaryBtn,
  bannerBtn,
  headerBtn,
  formBtn,
  spinnerBtn,
  dboardBtn,
  pricingBtn,
  bordered,
}) {
  return (
    <Fragment>
      {commonBtn && (
        <SiteBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </SiteBtn>
      )}

      {modalSecondaryBtn && (
        <ModalSecondaryBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </ModalSecondaryBtn>
      )}

      {commonBorderBtn && (
        <BorderBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
        </BorderBtn>
      )}

      {bannerBtn && (
        <BannerBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
          {!noRightIcon && <RightArrow />}
        </BannerBtn>
      )}

      {headerBtn && (
        <HeaderBtn id={id} onClick={onClick} style={style} disabled={disabled}>
          <span>{children}</span>
          {!noRightIcon && <RightArrow />}
        </HeaderBtn>
      )}

      {formBtn && (
        <FormBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled}>
          <span>{children}</span>
          {!noRightIcon && <RightArrow />}
        </FormBtn>
      )}

      {pricingBtn && (
        <PricingBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} variant={variant}>
          <span>{children}</span>
          {!noRightIcon && <RightArrow />}
        </PricingBtn>
      )}

      {spinnerBtn && (
        <SpinnerBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} ref={ref}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span> {!noRightIcon && <RightArrow />}
            </Fragment>
          )}
        </SpinnerBtn>
      )}

      {dboardBtn && (
        <DboardBtn id={id} onClick={onClick} style={style} active={active} disabled={disabled} loadingText={loadingText} ref={ref}>
          {active ? (
            <Fragment>
              <span className="pe-1">{loadingText ? loadingText : children}</span>
              <BtnLoadingCircle />
            </Fragment>
          ) : (
            <Fragment>
              <span>{children}</span>
            </Fragment>
          )}
        </DboardBtn>
      )}
    </Fragment>
  );
}
