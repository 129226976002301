import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import LoadableHoc from './loadableHOC';
import ProtectedRoute, { isAuthenticated } from './ProtectedRoute';
import { history } from '../Store';
import { ThemeProvider } from 'styled-components';
import { lightThemes, darkThemes } from '../Data/themeSheet';
import { SiteWrapper } from '../Components/UI/Layout';
import Home from '../Components/Home';

const Signup = LoadableHoc('Signup');
const Signin = LoadableHoc('Signin');
const Pricing = LoadableHoc('Pricing');
const Contact = LoadableHoc('Contact');
const Dashboard = LoadableHoc('Dashboard');

// policies
const PrivacyPolicy = LoadableHoc('Policies/PrivacyPolicy');
const RefundPolicy = LoadableHoc('Policies/RefundPolicy');
const FairUsagePolicy = LoadableHoc('Policies/FairUsagePolicy');
const Terms = LoadableHoc('Policies/Terms');

// product pages
const Testverse = LoadableHoc('Packages/Testverse');
const CodeCraft = LoadableHoc('Packages/CodeCraft');
const SuperLearner = LoadableHoc('Packages/SuperLearner');
const QuestGenerationAI = LoadableHoc('Packages/QuestGenerationAI');
const MockTestAI = LoadableHoc('Packages/MockTestAI');

// blogs
// const Blogs = LoadableHoc('Blogs');
const EducationWithAI = LoadableHoc('Blogs/EducationWithAI');

// const StripeTest = LoadableHoc('StripeTest');
const PaymentSuccessPage = LoadableHoc('Checkout/PaymentSuccessPage');

class RoutesWrapper extends Component {
  render() {
    const { darkTheme } = this.props;

    return (
      <ThemeProvider theme={darkTheme ? darkThemes : lightThemes}>
        <ConnectedRouter history={history}>
          {/* below jsx element is used to wrap mostly all site elements inside it */}
          <SiteWrapper>
            <BrowserRouter>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/signin" component={Signin} />
                <Route exact path="/pricing" component={Pricing} />
                {/*<Route exact path="/testverse/pricing" component={Pricing} />*/}
                <Route exact path="/contact" component={Contact} />

                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                <ProtectedRoute exact path="/dashboard/:pathID" component={Dashboard} />

                <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                <Route exact path="/refund-policy" component={RefundPolicy} />
                <Route exact path="/fair-usage-policy" component={FairUsagePolicy} />
                <Route exact path="/terms" component={Terms} />

                <Route exact path="/testverse" component={Testverse} />
                {/*<Route exact path="/codecraft" component={CodeCraft} />
                <Route exact path="/superlearner" component={SuperLearner} />*/}
                <Route exact path="/quest-gen-ai" component={QuestGenerationAI} />
                <Route exact path="/mock-test-ai" component={MockTestAI} />

                <Route exact path="/blog">
                  <Redirect to="/blog/ai-in-education" />
                </Route>
                <Route exact path="/blog/ai-in-education" component={EducationWithAI} />

                <Route exact path="/payment-success" component={PaymentSuccessPage} />
                {/*<Route exact path="/stripe-test" component={StripeTest} />*/}
                {isAuthenticated() ? <ProtectedRoute component={Dashboard} /> : <Route component={Home} />}
              </Switch>
            </BrowserRouter>
          </SiteWrapper>
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  darkTheme: User.darkTheme,
});

export default connect(mapStateToProps, null)(RoutesWrapper);
