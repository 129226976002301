import axios from 'axios';
import { getCookieIdToken } from './retriveToken';
import { fetchAuthSession } from 'aws-amplify/auth';
import amplifySignout from './amplifySignout';

const axiosInstance = (baseURL) => {
  const api = axios.create({
    baseURL: baseURL,
  });

  const setSessionExpiredKey = () => {
    localStorage.setItem('ssn-exp-mdl', true);
  };

  const logOutRedirection = () => {
    window.location.href = '/signin';
  };

  // Add a request interceptor
  api.interceptors.request.use(
    (config) => {
      const idToken = getCookieIdToken();
      // console.log('idToken: ', idToken);
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
      // console.log('config inside intercept request: ', config);
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      // console.log('originalRequest: ', originalRequest);

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await fetchAuthSession();
          // console.log('response inside intercept response: ', response);

          if (response && response.tokens !== undefined) {
            const newIdToken = getCookieIdToken();
            // console.log('newIdToken: ', newIdToken);

            // Retry the original request with the new token
            if (newIdToken) {
              originalRequest.headers.Authorization = `${newIdToken}`;
            }

            return axios(originalRequest);
          } else {
            console.log('unAuthorized user!');
            setSessionExpiredKey();
            await amplifySignout();
            logOutRedirection();
            return null;
          }
        } catch (error) {
          // Handle refresh token error or redirect to login
          // console.log('error inside api catch');
          setSessionExpiredKey();
          await amplifySignout();
          logOutRedirection();
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
};

export default axiosInstance;
