import styled from 'styled-components';

export const FooterBottomOuter = styled.footer`
  width: 100%;
  background: white;
`;

export const FooterBottomInner = styled.div`
  width: 100%;
  max-width: var(--layoutMaxWidth);
  margin: 0 auto;
  padding: 16px var(--siteXAxisPadd);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 992px) {
    // width: 95%;
  }
`;

export const FooterLogoWrapper = styled.p`
  font-size: 14px;
  line-height: normal;
  margin: 8px 24px 16px 0px;
`;

export const FooterLinksWrapper = styled.div``;

export const FooterLinks = styled.a`
  display: inline-block;
  font-size: 14px;
  color: var(--bs-gray-dark);
  margin: 4px 16px 8px 0px;
  &:last-child {
    margin-right: unset;
  }
  &:hover {
    color: var(--txtBrandColor);
  }
`;

export const SocialLinks = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--bs-gray-dark);
  text-decoration: none;
  background: var(--light);
  margin-right: 20px;
  border-radius: 2px;
  transition: var(--hoverTransitionDuration);
  &:last-child {
    margin-right: initial;
  }
  &:hover {
    color: var(--txtBrandColor);
  }
`;
