import React from 'react';
import styled from 'styled-components';
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { MdKeyboardArrowDown } from 'react-icons/md';

// accordian
export const SideNavItemContainer = styled(AccordionItem)`
  margin-bottom: 4px;
  border-bottom: 1px solid var(--greyBorderColor);
`;

// accord head div contaner
export const SideNavTitle = styled(AccordionItemHeading)`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  background-color: #fff;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const TitleWrapper = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sideNavYPadd) 0px;
  :focus {
    outline: none;
  }
  &[aria-expanded='true'] {
    border-bottom: 1px solid var(--greyBorderColor);
  }
`;

export const TitleContent = styled.p`
  width: 100%;
  margin-right: 8px;
  color: var(--txtHeadingColor);
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
`;

export const SideNavExpand = styled(MdKeyboardArrowDown)`
  font-size: 24px;
  line-height: normal;
  color: var(--bs-gray-700);
  transition: transform var(--hoverTransitionDuration);
  transform: ${(props) => props.isexpanded && 'rotateZ(180deg)'};
`;

// accord body
export const SideNavBody = styled(AccordionItemPanel).attrs({ className: 'accordion__body' })`
  display: grid !important;
  grid-template-rows: 1fr;
  transition: display var(--hoverTransitionDuration), grid-template-rows var(--hoverTransitionDuration), padding var(--hoverTransitionDuration);
  overflow: hidden;
  background-color: #fff;
  font-size: 14px;
  @media (min-width: 1800px) {
    font-size: 15px;
  }
  &[hidden] {
    display: grid !important;
    grid-template-rows: 0fr !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
  }
`;

export const SideNavBodyContent = styled.div`
  width: 100%;
  overflow: hidden;
`;

export default function SideNavAccordian({ groupName, groudId, children, bodyStyle, activeGroupId, onClick, onKeyDown }) {
  return (
    <SideNavItemContainer uuid={groudId}>
      <SideNavTitle onClick={onClick} onKeyDown={onKeyDown}>
        <TitleWrapper>
          <TitleContent>{groupName}</TitleContent>
          <SideNavExpand isexpanded={groudId === activeGroupId} />
        </TitleWrapper>
      </SideNavTitle>

      <SideNavBody style={bodyStyle}>
        <SideNavBodyContent>{children}</SideNavBodyContent>
      </SideNavBody>
    </SideNavItemContainer>
  );
}
