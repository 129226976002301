export const lightThemes = {
  pageBg: '#fff',
  cardBg: '#fff',
  cardInnerBg: '#f8f9fa',
  selectionBg: '#0000000d',
  txtPrimary: '#4d4d4d',
  txtSecondary: '#6c757d',
  txtIntense: '#343a40',
  txtInnerDiv: '#333333f2',
  borderColor: '#0000001a',
  inputsColor: '#242424',
  hoverBg: '#00000009',
  activeBg: '#00000012',
};

export const darkThemes = {
  pageBg: '#0f0f0f',
  cardBg: '#212020',
  cardInnerBg: '#333333f3',
  selectionBg: '#ffffff1a',
  txtPrimary: '#f1f1f1',
  txtSecondary: '#d1d1d1',
  txtIntense: '#f8f9fa',
  txtInnerDiv: '#f8f9fa',
  borderColor: '#ffffff33',
  inputsColor: '#d1d1d1',
  hoverBg: '#ffffff1e',
  activeBg: '#ffffff12',
};
