import { INIT_SIGNOUT, UPDATE_USAGE_THRESHOLD } from '../Actions/actionTypes';

const INIT_STATE = {
  usageLimitExceeded: false,
};

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SIGNOUT:
      return { ...INIT_STATE };

    case UPDATE_USAGE_THRESHOLD:
      return {
        ...state,
        usageLimitExceeded: action.status,
      };

    default:
      return state;
  }
};

export default Notification;
