import styled from 'styled-components';

export const OuterHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 3000;
  height: var(--headerHeight);
  transition: var(--hoverTransitionDuration);
  box-shadow: ${({ activeBorder, headerScrolling, mobileMenu }) => (activeBorder || headerScrolling || mobileMenu ? '0px 1px 1px var(--greyShadowColor)' : 'none')};
  @media (min-width: 992px) {
    box-shadow: unset;
    top: ${({ headerScrolling }) => headerScrolling && '8px'};
  }
`;

export const InnerHeader = styled.div`
  height: 100%;
  max-width: var(--layoutMaxWidth);
  padding: 0px var(--siteXAxisPadd);
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  transition: var(--hoverTransitionDuration);
  @media (min-width: 992px) {
    // width: 95%;
    border-radius: 50px;
    box-shadow: ${({ headerScrolling }) => (headerScrolling ? '0px 2px 20px var(--greyShadowColor)' : 'none')};
  }
`;

export const HeaderLogoWrapper = styled.div`
  width: 95px;
  display: block;
  user-select: none;
  margin-bottom: 4px;
`;

export const HeaderIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: block;
  user-select: none;
`;

export const HeaderNavWrapper = styled.nav`
  height: 100%;
  display: none;
  margin-left: 20px;
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

export const HeaderNavItem = styled.div.attrs({ className: 'head-nav-drop-item' })`
  height: 40px;
  padding: 5px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  color: var(--txtHeadingColor);
  line-height: 1.75;
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  cursor: pointer;
  background-color: ${({ active }) => active && 'var(--bgDboardLightColor)'};
  border-radius: var(--buttonRadius);
  // &:after {
  //   content: '';
  //   height: 2px;
  //   width: 0%;
  //   background: var(--bgBrandColor);
  //   position: absolute;
  //   left: 24px;
  //   left: 50%;
  //   bottom: 0px;
  //   transform: translateX(-50%);
  //   transition: width var(--hoverTransitionDuration);
  // }
  &:hover {
    background-color: var(--bgDboardLightColor);
  }
  &:active {
    background-color: var(--bgActiveLightColor);
  }
`;

export const HeaderNavLink = styled.a`
  height: 40px;
  padding: 5px 10px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  color: var(--txtHeadingColor);
  line-height: 1.75;
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
  border-radius: var(--buttonRadius);
  &:hover {
    background-color: var(--bgDboardLightColor);
  }
  // &:after {
  //   content: '';
  //   height: 2px;
  //   width: 0%;
  //   background: var(--bgBrandColor);
  //   position: absolute;
  //   left: 24px;
  //   left: 50%;
  //   bottom: 0px;
  //   transform: translateX(-50%);
  //   transition: width var(--hoverTransitionDuration);
  // }
  // &:hover&:after,
  // &:active&:after {
  //   width: 100%;
  // }
`;

export const HeaderSecondaryLink = styled.a`
  color: var(--txtBrandColor);
  font-size: var(--navFontSize);
  font-family: var(--commonFontMedium);
`;

export const HeaderCTAWrapper = styled.div`
  display: none;
  @media (min-width: 481px) {
    display: flex;
    align-items: center;
  }
`;

export const SideNavToggler = styled.div`
  font-size: 24px;
  color: var(--txtHeadingColor);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  border: 1px solid var(--borderColor);
  cursor: pointer;
`;
