import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookieIdToken } from '../Utils/retriveToken';

export const isAuthenticated = () => {
  const tokenPresent = getCookieIdToken();
  if (tokenPresent !== null) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Fragment>
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated() ? <Component {...props} /> : <Redirect to="/signin" />;
        }}
      />
    </Fragment>
  );
};

export default ProtectedRoute;
